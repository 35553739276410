<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">Daftar <b>Virtual Event</b></div>
        <b-button
          squared
          @click="$router.push('/pitching-events/add')"
          v-if="btnAccess"
          variant="primary"
          >Tambah</b-button
        >
      </b-alert>

      <div class="row">
        <div class="col-md-3 col-sm-6" v-for="item in items" :key="item.id">
          <v-hover v-slot="{ hover }">
            <router-link
              :to="'/pitching-events/detail/' + item.id"
              class="router-class"
            >
              <v-card width="400" :class="'class-highlight'">
                <v-img
                  height="200px"
                  :src="item.image_thumbnail"
                  style="background-size: contain"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        white
                        darken-2
                        v-card--reveal
                        display-3
                        white--text
                      "
                      style="height: 100%"
                    >
                      {{ item.name }}
                    </div>
                  </v-expand-transition>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-spacer></v-spacer>

                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :style="'z-index: 99'"
                          @click.stop.prevent
                        >
                          <v-icon
                            color="#000000"
                            :class="hover ? 'text-primary' : ''"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-card
                        class="mx-auto"
                        max-height="300px"
                        min-width="30%"
                        tile
                      >
                        <v-list dense>
                          <v-list-item-group color="primary" min-width="150">
                            <v-list-item
                              :to="'/pitching-events/edit/' + item.id"
                            >
                              <v-list-item-content> Edit </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="deleteData(item.id)">
                              <v-list-item-content> Hapus </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-app-bar>
                </v-img>
                <div :style="card_text_place" class="px-2">
                  <template>
                    <div
                      class="row np-gutters justify-content-between mt-2 mx-0"
                    >
                      <div class="">
                        <h5
                          class="card-title mb-4"
                          :class="hover ? 'text-primary' : ''"
                        >
                          {{ item.name }}
                        </h5>
                      </div>
                      <div class="">
                        <b-badge
                          v-if="item.status_id == 1"
                          variant="primary"
                          @click="showModalStatus($event, item.id)"
                          >{{ item.status_name }}</b-badge
                        >
                        <b-badge
                        v-if="item.status_id == 2"
                          style="background-color: #4caf50; color: white"
                          @click="showModalStatus($event, item.id)"
                          >{{ item.status_name }}</b-badge
                        >
                        <b-badge
                        v-if="item.status_id == 3"
                          variant="dark"
                          @click="showModalStatus($event, item.id)"
                          >{{ item.status_name }}</b-badge
                        >
                      </div>
                    </div>
                    <!-- <div class=" mt-4 text-hover-primary" :class="hover ? 'text-primary' : ''">
                      {{(item.description.length > 55) ? item.description.substr(0, 55) + "..." : item.description}}
                    </div> -->
                    <div class="my-3">
                      <span
                        class="font-weight-bold d-block"
                        :class="hover ? 'text-primary' : ''"
                        >Event</span
                      >
                      <span class="d-block" :class="hover ? 'text-primary' : ''"
                        >{{ item.start_time_display }} s/d
                        {{ item.end_time_display }}</span
                      >
                    </div>
                    <div class="">
                      <span
                        class="font-weight-bold d-block"
                        :class="hover ? 'text-primary' : ''"
                        >Discussion</span
                      >
                      <span class="d-block" :class="hover ? 'text-primary' : ''"
                        >{{ item.start_pitching_display }} s/d
                        {{ item.end_pitching_display }}</span
                      >
                    </div>
                    <div class="row no-gutters justify-content-end mt-2">
                      <div class="mr-2">
                        <v-badge
                          :content="item.hosts.length"
                          :value="item.hosts.length"
                          color="#c60001"
                          overlap
                          v-b-tooltip.hover.bottom
                          title="Moderator"
                        >
                          <v-icon
                            medium
                            class="fas fa-user"
                            :class="hover ? 'text-primary' : ''"
                          ></v-icon>
                        </v-badge>
                      </div>
                      <div class="mr-2">
                        <v-badge
                          :content="item.pitching_judgers.length"
                          :value="item.pitching_judgers.length"
                          color="#c60001"
                          overlap
                          v-b-tooltip.hover.bottom
                          title="Narasumber"
                        >
                          <v-icon
                            medium
                            class="fas fa-users"
                            :class="hover ? 'text-primary' : ''"
                          ></v-icon>
                        </v-badge>
                      </div>
                      <div>
                        <v-badge
                          :content="item.pitching_participants.length"
                          :value="item.pitching_participants.length"
                          color="#c60001"
                          overlap
                          v-b-tooltip.hover.bottom
                          title="Peserta & Mitra"
                        >
                          <v-icon
                            medium
                            class="fas fa-user"
                            :class="hover ? 'text-primary' : ''"
                          ></v-icon>
                        </v-badge>
                      </div>
                    </div>
                  </template>
                </div>
              </v-card>
            </router-link>
          </v-hover>
        </div>
      </div>
    </div>

    <b-modal id="modal-status" title="Status Event" @ok="changeStatus">
      <b-form-group
        label=""
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-group-2"
          v-model="formStatus.status_id"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
        >
          <b-form-radio :value="item.id" v-for="item in pitching_statuses" :key="item.id">{{item.name}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-modal>
  </v-app>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import moment from "moment";

export default {
  name: "Classroom",
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      card_text_place: "min-height: 170px",
      items: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      formStatus:{
        status_id: 1,
      },
      pitching_statuses: [],
      // access
      classroomView: false,
      btnAccess: true,
    };
  },
  methods: {
    async getPitchingStatus() {
      this.pitching_statuses = await module.get('api/pitching-statuses')
      // If Data Not Found
      if (this.pitching_statuses == null) {
      } else {
      }
    },

    async showModalStatus(e, id){
      e.preventDefault()
      this.formStatus = await module.get('api/pitching-events/' + id)
      // If Data Not Found
      if (this.formStatus == null) {
      } else {
        console.log('form status', this.formStatus)
      this.$bvModal.show('modal-status')
      }
    },

    async changeStatus(){
      // Make Request
      let response = await module.submit(this.formStatus, `api/pitching-events/${this.formStatus.id}/set-status`);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.pagination()
      }
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/pitching-events",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete(`api/pitching-events/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
          this.classroomView = true;
        }
        if (access_right[a] == "1002") {
          this.btn = true;
          this.classroomView = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
          this.classroomView = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Virtual Event" }]);
    this.checkAccess();
    this.pagination();
    this.getPitchingStatus();
  },
};
</script>

<style>
.v-image__image.v-image__image--cover {
  background-size: contain;
}
</style>

<style scoped>
.card-title {
  color: black;
}

.card-text {
  color: rgb(94, 92, 92) !important;
}

.teacher-name {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 15px;
}
.teacher-photo {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 6px;
  right: 10px;
}

.classroom-room {
  position: absolute;
  bottom: 16px;
}

.class-highlight:hover {
  color: #244ca3 !important;
}

.router-class:hover {
  color: #244ca3 !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>